import React, {useState} from "react";
import {HeadFC} from "gatsby";
import {Header} from "../components/header";
import {Footer} from "../components/footer";
import {inputStyle} from "./index";
import {sendContact} from "../data/supabase";
import {useParosSkeletonStyle} from "../hooks/use_paros_skeleton";
import {InfoDialog} from "../components/info_dialog";
import {SEO} from "../components/seo";

const ContactPage = () => {
  const [isOkDialogOpen, setOkDialogOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setLoading] = useState(false);

  const showOkDialog = () => {
    setOkDialogOpen(true);
  }

  const closeOkDialog = () => {
    setOkDialogOpen(false);
  }

  const clearFields = () => {
    setName('');
    setEmail('');
    setMessage('');
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    // build form inputs map
    let formValues = new Map<string, FormDataEntryValue>()
    const formData = new FormData(event.currentTarget);
    formData.forEach((value, key) => formValues.set(key, value));

    clearFields();

    setLoading(true);
    try {
      await sendContact(Object.fromEntries(formValues));
      setLoading(false);
      showOkDialog();
    } catch (ex) {
      setLoading(false);
    }
  };
  return (
    <main className="h-full">
      <Header/>
      <section className="h-full sm:h-3/5 max-w-6xl mx-auto pt-10 pb-14" style={useParosSkeletonStyle()}>
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <span className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 font-poppins">
              Need additional information?
            </span>
          <h1 className="mb-8 lg:mb-16 font-light text-gray-500 sm:text-xl">
            Please feel free to contact us. We will clarify any question as soon as possible.
          </h1>
          <form onSubmit={handleSubmit} className="space-y-8">
            <div>
              <label htmlFor="full_name"
                     className="block mb-2 text-sm font-medium text-gray-900">
                Full Name
              </label>
              <input
                type="text"
                id="full_name" name="full_name"
                className={inputStyle}
                placeholder="E.g: Giannis Antentokounmpo"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
                Email
              </label>
              <input
                type="email" id="email" name="email"
                className={inputStyle}
                placeholder="example@mail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required/>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows={6}
                className={inputStyle}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Leave your message here..."/>
            </div>
            <button type="submit"
                    className="orange-gradient hover:bg-black align-baseline p-3 w-full rounded-md text-white font-semibold text-sm md:text-md">
              {isLoading ?
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                  <path className="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                </svg> : null
              }
              Send message
            </button>
          </form>
        </div>
      </section>
      <InfoDialog
        title="Thank You for Contacting Us!"
        content="We have received your message and will get back to you soon! If your inquiry requires urgent attention, please feel free to call us at (+30) 2284052146 during our business hours."
        isOpen={isOkDialogOpen}
        onClose={closeOkDialog}/>
      <Footer/>
    </main>
  )
}

export const Head: HeadFC = () => (
  <SEO
    title="Contact Us | Rent a Car in Paros with Coolcar Rentals"
    description="Explore Paros at your own pace. Reserve your rental car today with our reliable services and friendly team. Contact us now."
  />
)

export default ContactPage;